'use strict'

import { isEmpty, isEqual } from 'lodash-es'
import { transformFilter, getFilterPartsByPredicate } from '../helpers/filters'
import currentUserFilterResolver from './currentUser'
import dataBindingFilterResolver from './databinding'
import userInputResolver from './userInput'
import { shouldResolve as shouldCurrentUserResolve } from '@wix/dbsm-common/src/filter-resolvers/currentUserFilterResolver'
import { shouldResolve as shouldDataBindingResolve } from '@wix/dbsm-common/src/filter-resolvers/dataBindingFilterResolver'
import { shouldResolve as shouldUserInputResolve } from '@wix/dbsm-common/src/filter-resolvers/userInputFilterResolver'
import { parseStandardFilter } from '../helpers/parseStandardFilter'
import { FieldType } from '@wix/wix-data-schema-types'
import { RESET_ALL } from '@wix/wix-data-client-common/src/userInputFilters'
import { USER_INPUT_FILTER_RANGE_SLIDER_ROLE } from '@wix/wix-data-client-common/src/connection-config/roles'
import { USER_INPUT_FILTER_ROLES } from '../helpers/constants'

const shouldResolve = filterExpression =>
  shouldCurrentUserResolve(filterExpression) ||
  shouldDataBindingResolve(filterExpression) ||
  shouldUserInputResolveWholeFilter(filterExpression)

const getConvertedValue = ({ value, fieldType }) =>
  fieldType === FieldType.number
    ? Array.isArray(value)
      ? value.map(Number)
      : Number(value)
    : value

const resolveFilter =
  ({ valueResolvers, getConnectedComponents, getFieldType }) =>
  filter => {
    const resolveExpressionValue = filterExpression => {
      if (shouldCurrentUserResolve(filterExpression)) {
        return valueResolvers.currentUser()
      }

      if (shouldDataBindingResolve(filterExpression)) {
        return valueResolvers.dataBinding(filterExpression)
      }

      if (shouldUserInputResolveWholeFilter(filterExpression)) {
        return valueResolvers.userInput(filterExpression)
      }
    }

    const maybeResolvedFilter = transformFilter(
      shouldResolve,
      resolveExpressionValue,
      filter,
    ).map(resolvedFilter => {
      const connectedComponents = getConnectedComponents()
      const filterInputComponents = connectedComponents.filter(({ role }) =>
        USER_INPUT_FILTER_ROLES.includes(role),
      )

      const userInputFilters = filterInputComponents
        .map(component => {
          const { properties } = component.connectionConfig
          const prop = Object.keys(properties)[0]
          const value = component.getValue({ propPath: prop })
          const { fieldName } = properties[prop]

          if (
            [RESET_ALL, '', false].includes(value) ||
            isEqual(value, []) ||
            (component.role === USER_INPUT_FILTER_RANGE_SLIDER_ROLE &&
              isEqual(value, [component.min, component.max]))
          ) {
            return null
          }

          const fieldType = getFieldType(fieldName).getOrElse(null)

          const convertedValue = getConvertedValue({ value, fieldType })

          const filterValue =
            component.role === USER_INPUT_FILTER_RANGE_SLIDER_ROLE
              ? { $gte: convertedValue[0], $lte: convertedValue[1] }
              : Array.isArray(convertedValue)
              ? { $hasSome: convertedValue }
              : convertedValue

          return { [fieldName]: filterValue }
        })
        .filter(Boolean)

      if (userInputFilters.length === 0) {
        return resolvedFilter
      }
      return { $and: [resolvedFilter].filter(Boolean).concat(userInputFilters) }
    })

    return maybeResolvedFilter
  }

const shouldUserInputResolveWholeFilter = filterExpression =>
  parseStandardFilter(filterExpression)
    .map(({ value }) => shouldUserInputResolve(value))
    .getOrElse(false)

// getPartsForDatabindingResolver :: Filter -> [FilterPart]
const getPartsForDatabindingResolver = filter =>
  getFilterPartsByPredicate(shouldDataBindingResolve, filter)

// hasPartsForUserInputResolver :: Filter -> Boolean
const hasPartsForUserInputResolver = filter =>
  !isEmpty(getFilterPartsByPredicate(shouldUserInputResolve, filter))

// hasPartsForCurrentUserResolver :: Filter -> Boolean
const hasPartsForCurrentUserResolver = filter =>
  !isEmpty(getFilterPartsByPredicate(shouldCurrentUserResolve, filter))

// hasDatabindingDependencies :: Filter -> Boolean
const hasDatabindingDependencies = filter =>
  getPartsForDatabindingResolver(filter).length > 0

const hasDynamicFilter = filter =>
  hasPartsForUserInputResolver(filter) ||
  hasPartsForCurrentUserResolver(filter) ||
  hasDatabindingDependencies(filter)

const createValueResolvers = (
  getDependencyById,
  getConnectedComponents,
  getFieldType,
) => ({
  dataBinding: dataBindingFilterResolver(getDependencyById),
  currentUser: currentUserFilterResolver(),
  userInput: userInputResolver({ getConnectedComponents, getFieldType }),
})

export {
  resolveFilter as createFilterResolver,
  createValueResolvers,
  getPartsForDatabindingResolver as getExpressions,
  hasPartsForUserInputResolver as hasUserInputDependencies,
  hasDynamicFilter,
}
